import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../components/Layout'
import Content from '../components/Content'
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ReactMarkdown from "react-markdown";
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import './offer.scss'

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#fff',
      main: 'rgb(23, 105, 170)',
      dark: '#000'
    },
    secondary: {
      main: '#4da7af',
    },
  },
});

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

const VerticalTabs = ({data}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Layout>
      <Content>
        <h1 className="heading">{data.strapiPage.title}</h1>
        <div className="offer">
          <ReactMarkdown source={data.strapiPage.content}/>
        </div>
        <MuiThemeProvider theme={theme}>
          <div className={classes.root}>
            <AppBar position="static" color="default">
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                indicatorColor="secondary"
                textColor="secondary"
              >
                {data.strapiPage.Offer.map(document => (
                  <Tab
                    key={document.id}
                    label={document.title}
                    {...a11yProps(document.id)}
                  />
                ))}
              </Tabs>
            </AppBar>
            {data.strapiPage.Offer.map(document => (
              <TabPanel
                key={document.id}
                value={value}
                index={document.id - 1}>
                <ReactMarkdown source={document.content}/>
              </TabPanel>
            ))}
          </div>
        </MuiThemeProvider>
      </Content>
    </Layout>
  );
}

export default VerticalTabs

export const pageQuery = graphql`
  query OfferQuery {
    strapiPage(slug: {eq: "oferta"}) {
      Offer {
        id
        content
        title
      }
      content
      created_at
      title
    }
  }
`
